<template>
  <div class="help contentWrapper">
    <h1>Help</h1>
    <threeWide>
      <link-card
        label="Downloads"
        boldLabel
        description="Software &amp; Manuals"
        img="icon-download.png"
        btnLink="/downloads/"
        btnLabel="View Downloads"
      ></link-card>
      <link-card
        label="Forums"
        boldLabel
        description="Join the Community"
        img="icon-forums.png"
        btnLink="https://forums.naturalpoint.com/viewforum.php?f=72"
        v-bind:externalLink="true"
        btnLabel="Visit the Forums"
      ></link-card>
      <link-card
        label="Documentation"
        boldLabel
        description="Setup Guides"
        img="icon-docs-solid-light-transparent.svg"
        btnLink="https://docs.trackir.com/"
        v-bind:externalLink="true"
        btnLabel="Read the Docs"
      ></link-card>
    </threeWide>

    <h2 class="belt"><span>Contact</span></h2>

    <!-- Response after form sent -->
    <div v-if="formSent && formResponse" class="userMessageWrapper">
      <h2>Message received</h2>
      <p>Thank you for contacting us</p>
    </div>
    <!--END form sent message-->

    <!-- Processing Form -->
    <div v-if="formSent && !formResponse" class="userMessageWrapper">
      <img
        class="loadingSpinner"
        :src="imagesUrl + 'spinner.svg'"
        alt="Loading Spinner"
      />
      <p>Processing your request</p>
    </div>
    <!--END Processing Form-->

    <div v-if="!formSent" class="colsWrapper">
      <div class="col">
        <form-input-text
          label="Customer Name*"
          ref="contactName"
          :value="contact.name"
          v-on:input="contactNameChange"
          v-on:valid="validate('nameValid', true)"
          v-on:notValid="validate('nameValid', false)"
          id="name"
          tabIndex="1"
          name="name"
          validation="required"
        >
        </form-input-text>

        <div class="twoCol">
          <form-input-text
            label="Email*"
            ref="contactEmail"
            :value="contact.email"
            v-on:input="contactEmailChange"
            v-on:valid="validate('emailValid', true)"
            v-on:notValid="validate('emailValid', false)"
            id="email"
            tabIndex="2"
            name="email"
            validation="required"
          >
          </form-input-text>
          <form-input-text
            label="Phone"
            :value="contact.phone"
            v-on:input="contactPhoneChange"
            id="phone"
            tabIndex="3"
            name="phone"
            validation="required"
          >
          </form-input-text>
        </div>
        <!--END twoCol-->
        <form-input-select
          id="subject"
          label="Request Type*"
          ref="contactSubject"
          :value="contact.subject"
          v-on:change="contactSubjectChange"
          v-on:valid="validate('subjectValid', true)"
          v-on:notValid="validate('subjectValid', false)"
          name="subject"
        >
          <option value="Technical support">Technical support</option>
          <option value="Replacement parts request">Replacement parts request or returns</option>
          <option value="Track my new order">Sales or order inquiries</option>
        </form-input-select>
      </div>
      <!--END col-->
      <div class="col">
        <div class="twoCol">
          <form-input-text
            label="Order Number"
            :value="contact.order"
            v-on:input="contactOrderChange"
            id="order"
            tabIndex="3"
            name="order"
            validation="required"
          >
          </form-input-text>
          <form-input-text
            label="Serial Number"
            :value="contact.serial"
            v-on:input="contactSerialChange"
            id="serial"
            tabIndex="4"
            name="serial"
            validation="required"
          >
          </form-input-text>
        </div>
        <!--END twoCol-->
        <form-input-textarea
          label="Describe how we can help*"
          ref="contactDescription"
          :value="contact.description"
          v-on:input="contactDescriptionChange"
          v-on:valid="validate('descriptionValid', true)"
          v-on:notValid="validate('descriptionValid', false)"
          id="description"
          validation="required"
          name="description"
        >
        </form-input-textarea>

        <input
          id="submitBtn"
          type="submit"
          class="submitBtn"
          value="Submit"
          @click.prevent="validateCaptcha()"
        />


        <div class="recaptcha-notice">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
            <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
        </div><!--END recaptcha-notice-->
        <div ref="grecaptcha"></div>

      </div>
      <!--END col-->
    </div>
    <!--END colsWrapper-->
  </div>
  <!--END contentWrapper-->
</template>
<script>
import { IMAGES_URL } from "@/constants/";
import { mapState } from "vuex";
import { SET_CONTACT_NAME } from "../store/mutation-types";
import { SET_CONTACT_EMAIL } from "../store/mutation-types";
import { SET_CONTACT_PHONE } from "../store/mutation-types";
import { SET_CONTACT_SUBJECT } from "../store/mutation-types";
import { SET_CONTACT_ORDER } from "../store/mutation-types";
import { SET_CONTACT_SERIAL } from "../store/mutation-types";
import { SET_CONTACT_DESCRIPTION } from "../store/mutation-types";
import { SET_CONTACT_IP } from "../store/mutation-types";
import { SET_CONTACT_CAPTCHA } from "../store/mutation-types";
import ThreeWide from "@/components/Layouts/ThreeWide.vue";
import LinkCard from "@/components/LinkCard.vue";
import FormInputText from "@/components/Inputs/FormInputText";
import FormInputSelect from "@/components/Inputs/FormInputSelect";
import FormInputTextarea from "@/components/Inputs/FormInputTextarea";
export default {
  name: "help",
  data: function () {
    return {
      formSent: false,
      formValid: false,
      nameValid: false,
      emailValid: false,
      subjectValid: false,
      descriptionValid: false,
      formResponse: false,
      captchaKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
    };
  },
  computed: {
    ...mapState({
      contact: (state) => state.contact.formData,
      contactSummaryData: (state) => state.contactSummary,
    }),
    imagesUrl: function () {
      return IMAGES_URL;
    },
  },
  components: {
    ThreeWide,
    LinkCard,
    FormInputText,
    FormInputSelect,
    FormInputTextarea,
  },
  mounted() {
    //gets the clients ip address
    this.fetchIp();

    //initialize recaptcha
    window.ReCaptchaLoaded = this.loaded;

    //add external google recaptcha script
    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=ReCaptchaLoaded');
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    contactNameChange: function (newValue) {
      this.$store.commit(SET_CONTACT_NAME, newValue);
    },
    contactEmailChange: function (newValue) {
      this.$store.commit(SET_CONTACT_EMAIL, newValue);
    },
    contactPhoneChange: function (newValue) {
      this.$store.commit(SET_CONTACT_PHONE, newValue);
    },
    contactSubjectChange: function (newValue) {
      this.$store.commit(SET_CONTACT_SUBJECT, newValue);
    },
    contactOrderChange: function (newValue) {
      this.$store.commit(SET_CONTACT_ORDER, newValue);
    },
    contactSerialChange: function (newValue) {
      this.$store.commit(SET_CONTACT_SERIAL, newValue);
    },
    contactDescriptionChange: function (newValue) {
      this.$store.commit(SET_CONTACT_DESCRIPTION, newValue);
    },
    contactIpChange: function (newValue) {
      this.$store.commit(SET_CONTACT_IP, newValue);
    },
    contactCaptchaChange: function (newValue) {
      this.$store.commit(SET_CONTACT_CAPTCHA, newValue);
    },

    loaded(){
      //renders the recaptcha tag when script is loaded
      window.grecaptcha.render(this.$refs.grecaptcha, {
        sitekey: this.captchaKey,
        size: 'invisible',
        callback: (response) => {
          this.sendForm(response);
        }
      });
    },

    //checks if all inputs are valid
    validate: function (validProp, valid) {
      // sets checkout property if input value is valid
      this[validProp] = valid;

      /* Check for valid form */
      if (
        this.nameValid &&
        this.emailValid &&
        this.subjectValid &&
        this.descriptionValid
      ) {
        this.formValid = true;
      } else {
        this.formValid = false;
      }
    },
    validateAllInputs: function () {
      //name
      if (this.$refs.contactName) {
        this.$refs.contactName.checkValidation();
      }
      //email
      if (this.$refs.contactEmail) {
        this.$refs.contactEmail.checkValidation();
      }
      //subject
      if (this.$refs.contactSubject) {
        this.$refs.contactSubject.checkValidation();
      }
      //description
      if (this.$refs.contactDescription) {
        this.$refs.contactDescription.checkValidation();
      }
    },
    fetchIp: function () {

      fetch("https://api.db-ip.com/v2/free/self")
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.contactIpChange(data);
        });
    },
    validateCaptcha: function () {
      this.validateAllInputs();
      
      /* execute the reCAPTCHA system - will call sendForm() when done  */
      grecaptcha.execute();

    },
    sendForm: function (captchaResponse) {
      //set the response from google api
      this.contactCaptchaChange(captchaResponse);
      
      if (this.formValid) {
        this.formSent = true;
        this.$store.dispatch("sendContactForm").then((response) => {
          this.formResponse = true;

          //invalidate form
          this.formValid = false;
          this.nameValid = false;
          this.emailValid = false;
          this.subjectValid = false;
          this.descriptionValid = false;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_layouts.scss";

h2 {
  margin-bottom: 0px;
}

.belt {
  line-height: 0.5;
  text-align: center;
  margin: 80px 0;

  span {
    display: inline-block;
    position: relative;
  }
}
.belt span:before,
.belt span:after {
  content: "";
  position: absolute;
  border-top: 1px solid hsla(0, 0%, 100%, 0.5);
  top: 50%;
  width: 200px;
}
.belt span:before {
  right: 100%;
  margin-right: 65px;
}
.belt span:after {
  left: 100%;
  margin-left: 65px;
}
.help {
  text-align: center;

  h1 {
    margin-bottom: 0px;
  }
  h3 {
    font-weight: 700;
    margin-bottom: 0px;
  }
  p {
    margin: 0px;
  }

  ::v-deep .productCard .productImg img {
    width: 120px;
  }
}

.userMessageWrapper {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px 0 40px;

  .loadingSpinner {
    margin-top: 40px;
  }
  h2 {
    margin-bottom: 20px;
  }
}

.colsWrapper {
  .col {
    .formInputText,
    .formInputSelect,
    .formInputTextarea {
      margin-bottom: 30px;
    }
  }
}

.selectWrapper {
  position: relative;
  width: 100%;

  /* Change autofill color ;) */
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px #bd6f01 inset !important;
    border: 1px solid #c07100 !important;
  }
  /*Change text in autofill textbox*/
  select:-webkit-autofill {
    -webkit-text-fill-color: #ffffff !important;
  }

  select {
    box-sizing: border-box;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px 8px 3px 8px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #ffffff;
    outline: none;

    optgroup {
      color: #000000;
    }
    optgroup option,
    option {
      color: #000000;
      font-size: 12px;
    }
  }

  .error {
    border: 1px solid red;
  }

  select:focus + label,
  select.content + label {
    top: 3px;
    font-size: 10px;
  }

  label {
    position: absolute;
    top: 10px;
    left: 8px;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s;
    pointer-events: none;
    font-size: 12px;

    @include for-medium-up {
      font-size: 16px;
    }
  }
}

.submitBtn {
  transition: all $default-transition-duration $default-transition-function;
  padding: 10px 52px;
  border: solid 1px var(--color-text-default);
  border-radius: $border-radius-large;
  text-decoration: none;
  display: inline-block;
  color: #ffffff;
  margin: 0px 2px 40px;
  background-color: transparent;

  &:hover {
    color: var(--color-text-emphasized);
    background: var(--color-input-darken);
    cursor: pointer;
  }
}
.recaptcha-notice {
  padding:0 50px 50px;
  font-size:12px;
  opacity:0.5;

  a{
    text-decoration:underline;
  }
}

</style>
