<template>
  <div class="linkCard">
    <div v-if="img" class="productImg">
      <img :src="getImgUrl(img)" :alt="label" />
    </div>
    <div
      v-if="label"
      class="productLabel"
      v-bind:class="{ bold: boldLabel === true }"
    >
      {{ label }}
    </div>
    <div v-if="description" class="productDescription">{{ description }}</div>
    <div v-if="externalLink && btnLink" class="productBtn">
      <a :href="btnLink" target="_blank">{{ btnLabel }}</a>
    </div>
    <div v-if="!externalLink && btnLink" class="productBtn">
      <router-link :to="btnLink">{{ btnLabel }}</router-link>
    </div>
  </div>
  <!-- END productCard -->
</template>

<script>
import { IMAGES_URL } from "@/constants/";
export default {
  name: "LinkCard",
  props: {
    label: { type: String },
    boldLabel: { type: Boolean, default: false },
    description: { type: String },
    img: { type: String },
    coupon: { type: String },
    btnLabel: { type: String, default: "Add to Cart" },
    btnLink: { type: String },
    externalLink: { type: Boolean, default: false },
  },
  methods: {
    getImgUrl(pic) {
      return IMAGES_URL + pic;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.linkCard {
  text-align: center;
  padding: 10px;
  align-self: end;
  position: relative;

  .productImg {
    padding: 20px 0 0;
    max-height: 175px;
    box-sizing: border-box;
    img {
      max-width: 100%;
      max-height: 145px;
      vertical-align: bottom;
    }
  }
  .productLabel {
    margin: 10px 0px;

    &.bold {
      font-weight: 700;
      color: #ffffff;
    }
  }
  .productDescription {
    margin: 10px 0px;
  }

  .checkoutLink a {
    position: absolute;
    left: 0px;
    right: 0px;
    text-align: center;
    padding-top: 10px;
    text-decoration: underline;
    animation-duration: 0.5s;
    animation-name: slideDown;

    &:hover {
      color: #ffffff;
    }
  }
}
.productBtn {
  white-space: nowrap;
}

.productBtn a {
  @include btn();
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  display: block;
  position: relative;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
</style>